<script>
export default {
  name: "DiscordAuth",
  data() {
    return {
      sessionToken: null
    }
  },
  mounted() {
    const sessionToken = this.$cookies.get("GlobalChat-Login");
    const code = this.$route.query.code;
    if (code === undefined || sessionToken == null) {
      this.$router.push({path: "/dashboard"});
      return;
    }
    fetch("https://api.globalchataddon.de/dashboard/discord/oauth/link", {
      method: "POST",
      headers: {
        token: sessionToken,
        discordCode: code
      }
    }).then(value => value.text()).then(json => {
      // TODO check if the request was success
      this.$router.push({path: "/dashboard"});
      console.log(json);
    });
  }
};
</script>